/* eslint no-console:0 */
// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.
//
// To reference this file, add <%= javascript_pack_tag 'application' %> to the appropriate
// layout file, like app/views/layouts/application.html.erb


// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)

var userAgent = window.navigator.userAgent.toLowerCase();
var browerType = judgeBrowserType(userAgent);

if ( browerType == 'IE' ) {
  alert('InternetExplorerは使えません、GoogleCromeを使用してください');
}else{
  console.log(browerType)
}

///////////////////////////////////////////////////////////////////////////////
// ユーザーエージェントからブラウザタイプを判定
function judgeBrowserType(UserAgent){
  if(UserAgent.indexOf('msie') != -1 ||
  UserAgent.indexOf('trident') != -1) {
    return 'IE';
  } else if(UserAgent.indexOf('edge') != -1) {
      return 'Edge';
  } else if(UserAgent.indexOf('chrome') != -1) {
      return 'Chrome';
    } else if(UserAgent.indexOf('crios') != -1) {
      return 'Chrome';
    } else if(UserAgent.indexOf('safari') != -1) {
      return 'Safari';
  } else if(UserAgent.indexOf('firefox') != -1) {
      return 'FireFox';
  } else if(UserAgent.indexOf('opera') != -1) {
      return 'Opera';
  } else {
      return null;
  }
}
